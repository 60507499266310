import * as qs from 'qs'

export default class UrlExchange {
    constructor(loc) {
        if (typeof loc !== 'undefined') {
            this.setLoc(loc)
        }
    }

    /**
     * expose setter for location.
     * encaps all support logic for updating location.
     * makes testing easier, and makes the lib more versatile
     */
    setLoc(loc) {
        this.loc = loc
        let paramType = false
        this.params = false
        this.parse()
    }

    /**
     * parse url hash/query into usable params
     */
    parse() {
        let prm = false
        let s = this.loc.search
        let h = this.loc.hash

        if (h === "" && s === "") {
            // escape if there's nothing to do
            this.params = false
            this.paramType = false
            return false
        } else if (s.length) {
            this.paramType = 'search'
            prm = qs.parse(s.substring(1))
        } else if (h.length) {
            this.paramType = 'hash'
            prm = qs.parse(h.substring(1))
        }

        // check for a parsed mtc key to avoid sending nonsense/unrelated prms
        this.params = prm.hasOwnProperty('contact') ? prm.contact : false
    }

    /**
     * expects object of fields
     * @param contactOb
     * @returns {string}
     */
    encode(contactOb) {
        return qs.stringify({contact: contactOb}, {encode: true})
    }

    /**
     * Returns base url - minus params - for use in window.history.pushState()
     * returns false if the url doesn't have contact params to sanitize
     * @returns {string|boolean}
     */
    getClean() {
        if (!this.paramType) {
            return false
        }
        let omit = this.loc[this.paramType]
        return this.loc.href.replace(omit, '')
    }
}
